<!--Grid row-->
<!-- <div class="container-fluid" *transloco="let t"> -->
<div *transloco="let t">
  <multisite-jumbo-greeting
    [bannerImageTransformations]="'h_500,c_fill,ar_16:9/'"
    [featureImageTransformations]="'h_500,c_fill/'"
    [featureImage]="featureImage"
    [bannerImage]="bannerImage"
    [titleTranslationKey]="'content.test_your_knowledge'"
    [subtitleTranslationKey]="'culturequestion_content.promotion.before_life_tests'"
  ></multisite-jumbo-greeting>

  <div class="">
    <div class="container">
        <multisite-access-code-user-input></multisite-access-code-user-input>
    </div>
  </div>
  <div class="container">
    <div class="row my-5 py-5" *ngIf="subdomain">
      <culture-selector-type-two
      *ngIf="mapFeatures"
      (selectedCulture)="updateMapCultures($event)"
      [selectedCultureIsos]="mapCulturesSelectedIsos"
      [featureCollectionName]="mapCulturesGeoJsonCollectionName"
      [features]="mapFeatures"
      [clusters]="mapRegionClusters"
      [hideUnavailableCulturesFromList]="false"
      [preambleTranslationKey]="'common.no_access_code_try_these'"
      [titleTranslationKey]="'culturequestion.choose_culture_find_quiz'"
      [maxZoom]="5"
      ></culture-selector-type-two>
    </div>
    <div class="row my-5 py-5" *ngIf="subdomain">
      <div class="title-holder">
        <p class="title h3">{{t('content.latest_releases')}}</p>
      </div>
      <multisite-content-list-formats
        [contentItems]="surveysAsContent"
        [imageTransformations]="'w_700,c_fill,ar_16:9/'"
        (itemSelected)="gotoSurvey($event)"
      >
      </multisite-content-list-formats>
    </div>
    <div class="row my-5 py-5" *ngIf="!subdomain">
      <multisite-number-thumbnails-banner
      [titleTranslationKey]="'culturequestion.get_access_title'"
      [descriptionTranslationKey]="'culturequestion.get_access_description'"
      [statistic]="communityStatistic"
      (linkClicked)="navigateTo('community')"
      ></multisite-number-thumbnails-banner>
    </div>
  </div>

</div>