import { Component, OnInit, HostListener } from '@angular/core';
import { ApplicationService, AuthService, LogoutService } from '@frontend/common';
import { ActivityLogService } from '@frontend/core';

import { User } from '@frontend/common';
import { MainNavigationLink } from '@frontend/common';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs';

@Component({
  selector: 'app-ttt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Culture TTT';

  logoutObs;
  user: User = null;
  userSubscription;
  isAuthenticated: boolean = false;
  loading = false;
  error = null;
  sidenavStatus : {action:string,mode:string,hidden:boolean};
  mainNavigationLinks: MainNavigationLink[];
  brandLogoBanner: string; // temporary solution TODO move this logic elsewhere
  headerScrollOffsetExceeded = false;
  currentRouteIsHomepage: boolean;

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollPosition = (event.target as HTMLElement).scrollTop || 0;
    const headerHeight = document.querySelector("nav")?.offsetHeight || 0;
    // console.log(scrollPosition+': Scroll Position of mainSection');
    // console.log(headerHeight+': headerHeight');
    if (scrollPosition >= headerHeight) {
      this.headerScrollOffsetExceeded = true;
    } else {
      this.headerScrollOffsetExceeded = false;
    }
  }


  constructor(
    private authService: AuthService,
    private activityLogService: ActivityLogService,
    private logoutService : LogoutService,
    private applicationService : ApplicationService,
    private router : Router,
  ) {
    this.mainNavigationLinks = [
      {labelText: 'Quizzes', labelLanguageKey : 'content.quiz', routerLinkValue: ['/quiz'], condition: ''},
      // {labelText: null, labelLanguageKey : 'navigation.cco', routerLinkValue: ['/cultureconnector'], condition: ''},
      // // {labelText: null, labelLanguageKey : 'navigation.lesson', routerLinkValue: ['/design/13'], condition: 'hideIfNoUser'},
      // {labelText: null, labelLanguageKey : 'navigation.lesson', routerLinkValue: ['/design/13'], condition: 'hideIfNotAdmin'},
    ];
  }

  logout() {
    this.loading = true;
    this.logoutObs = this.logoutService.logout();
    this.logoutObs.subscribe(
      (response) => {
        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }
  
  getUser() {
    this.loading = true;
    this.error = null;

    let getUserSub = this.authService.getUser(null).subscribe(
      () => {
        this.loading = false;
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.loading = false;
      }
    );
  }
  handleNoSubdomain (){
    this.mainNavigationLinks = this.mainNavigationLinks.filter(l=>l.labelText !== 'Quizzes');
  }
  ngOnInit() {
    if(!this.applicationService.getSubdomain()){
      this.handleNoSubdomain();
    }
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
        this.isAuthenticated = !!user;
        this.activityLogService.logDefaultSiteVisit();
      },
      (error) => {
        this.activityLogService.logDefaultSiteVisit();
      }
    );
    if (this.authService.token) {
      this.getUser();
    }
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.currentRouteIsHomepage = this.router.url === '/';
    })
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.logoutObs.unsubscribe();
  }
}
