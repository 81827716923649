import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService, AvailableLanguage, BrandDisplayService, CloudinaryMediaAsset, ContentItem, Culture, CultureService, CultureWrapperWithProperties, LanguageService } from '@frontend/common';
import { HubMicro } from '@frontend/common';
import { Survey } from '@frontend/common';
import { SurveysService } from '@frontend/common';
import { StatisticNumerical, Tag } from '@frontend/core';
import { CultureClusterWrapper } from 'libs/common/src/lib/clusters/culture-cluster-wrapper.model';
import { GeoJsonFeature } from 'libs/common/src/lib/maps/geojson-feature.model';
import { Subscription } from 'rxjs';



@Component({
  selector: 'culturequestion-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  surveys : Survey[];
  mapCulturesGeoJson : GeoJsonFeature[]; // Do we need this? Can it stay in the Service?
  mapFeatures : GeoJsonFeature[]; // transformed and ready with map data
  mapCultures : Culture[]; // Do we need this? Can it stay in the Service?
  mapCulturesSelected : Culture[] = [];
  mapCulturesSelectedIsos : string[] = []; // if we use a function to map these in the template, there is massive change detection
  mapCulturesGeoJsonCollectionName : string;
  mapRegionClusters : CultureClusterWrapper[];
  cultureSlugsFromSurveyTags : string[]; // TODO survey-tags are too unreliable; should be survey-cultures
  allowSelectionOfMultipleCultures : boolean;
  surveysAsContent : ContentItem[];
  loading: boolean;
  hubMicro: HubMicro;
  featureImage : CloudinaryMediaAsset;
  bannerImage : CloudinaryMediaAsset;
  hubMicroSubscription : Subscription;
  defaultBannerImage : CloudinaryMediaAsset;
  culturesSubscription: Subscription;
  activeLanguageSubscription: Subscription;
  activeLanguageObject: AvailableLanguage; // TODO - this should not be necessary
  subdomain: string;
  communityStatistic: StatisticNumerical;

  constructor(
    private router : Router,
    private route : ActivatedRoute,
    private surveysService: SurveysService,
    private brandDisplayService : BrandDisplayService,
    private languageService : LanguageService,
    private applicationService: ApplicationService,
    private cultureService: CultureService,
    ) { 
      this.defaultBannerImage = 
      {
        id: 195,
        medially_type:  'App\\Models\\Page',
        medially_id: 6,
        file_url: 'https://res.cloudinary.com/cebt/image/upload/v1681744158/concepts/questionnaire-student-in-test-with-two-cheats-SBI-300726884-storyblocks-small_p0yeky.jpg',
        file_name: 'concepts/questionnaire-student-in-test-with-two-cheats-SBI-300726884-storyblocks-small_p0yeky.jpg',
        file_type: "image",
        size: 5310000,
        uploader_id: 1,
        description: "Two students try to see a third student's paper during a test",
        credits: "Storyblocks",  // "Richard Farkas",
        permission: "Storyblocks subcription", // null,
        category: "concepts", // "intercultural",
        type: "knowledge", // "concepts",
        created_at: null, // "2022-10-31T13:00:03.000000Z",
        updated_at: null, // "2022-10-31T13:00:03.000000Z"
        // we add the following here in the frontend:
        base_url: 'https://res.cloudinary.com/cebt/image/upload/',
        transformations_url: null, //w_700,c_fill,ar_16:9/
        asset_url: 'v1681744158/concepts/questionnaire-student-in-test-with-two-cheats-SBI-300726884-storyblocks-small_p0yeky.jpg'
      }
    }
  setCultureProperties(cultures:Culture[],cultureSlugsFromSurveyTags:string[],mapCulturesSelected:Culture[]):CultureWrapperWithProperties[]{
    return cultures.map(c=> {
      return {
        properties: {
          culture : c,
          available : cultureSlugsFromSurveyTags?.includes(c.slug),
          unavailable : !cultureSlugsFromSurveyTags?.includes(c.slug),
          selected : mapCulturesSelected?.map(c=>c.slug).includes(c.slug)
        }
      }
    });
  }
  getSurveys(category : string,type : string, freshFromServer: boolean){
    this.loading = true;
    this.surveysService.getSurveys (category,type,null,freshFromServer).subscribe( response =>{
      this.surveys = response;
      this.surveysAsContent = this.surveysService.convertSurveysToContentItems(this.surveys);
      this.cultureSlugsFromSurveyTags = this.surveysAsContent.flatMap(s=>(s.tags as Tag[])).map(t=>t.slug).filter((value, index, self) => self.indexOf(value) === index); // the filter returns only unique values
      this.getCultures('geographic','national','geometry_110',freshFromServer);
      this.loading = false;
  },
    error => {
      this.loading = false;
    })
  }
  getCultureClustersData (culture_category: string, culture_type: string, cluster_category: string, cluster_type:string, freshFromServer:boolean){
    this.cultureService.getCultureClustersData(culture_category,culture_type,cluster_category,cluster_type,freshFromServer).subscribe(([cultures, clusters, cultureClusters]) => {
      let results = [cultures, clusters, cultureClusters];
      this.mapRegionClusters = this.cultureService.prepareCultureClusters(cultures,clusters,cultureClusters,'culture_maps',null);
    }, error => {
      // TODO Handle errors
    });
  }
  updateMapFeatures(mapCultures: Culture[],mapCulturesGeoJson:GeoJsonFeature[],cultureSlugsFromSurveyTags:string[],mapCulturesSelected:Culture[]){
    const cultureWrappers = this.setCultureProperties(mapCultures, cultureSlugsFromSurveyTags,mapCulturesSelected);
    return this.cultureService.prepareGeoJsonDataForMap(cultureWrappers, mapCulturesGeoJson);
  }
  async getCultures(category: string, type: string, geometry_type: string, freshFromServer: boolean): Promise<void> {
    try {
      this.loading = true;
      const [cultures, geoJson] = await this.cultureService.getCulturesAndGeoJson(category, type, geometry_type, freshFromServer);
      
      // Handle the cultures and GeoJSON
      this.mapCultures = cultures;
      this.mapCulturesGeoJson = geoJson;
      this.mapFeatures = this.updateMapFeatures(cultures,geoJson,this.cultureSlugsFromSurveyTags, this.mapCulturesSelected);
      this.mapCulturesGeoJsonCollectionName = 'Available cultures';
      this.getCultureClustersData(category, type, 'culture_maps', null, freshFromServer);
      this.loading = false;
    } catch (error) {
      // TODO handle errors
      this.loading = false;
    }
  }
  selectSingleCulture(culture:Culture){
    let surveysTaggedWithCulture = this.surveysAsContent.filter(s=>s.tags.map(t=>t.slug).includes(culture.slug));
    if (surveysTaggedWithCulture.length > 1){
      this.router.navigate(['/search'],{queryParams:{cultures:culture.slug},relativeTo:this.route})
    } else if (surveysTaggedWithCulture.length == 1){
      this.router.navigate(['/quiz/'+surveysTaggedWithCulture[0].slug])
    } else {
      alert('None found'); // some logical error; should be impossible
    }
  }

  updateMapCultures(culture:Culture){ // Use this when allowing multiple cultures to be selected
    if(!culture?.iso){return;} // we have a problem
    if(!this.allowSelectionOfMultipleCultures){
      this.selectSingleCulture(culture);
    } else {
      let foundIndex = this.mapCulturesSelected.findIndex(mc=>mc.iso === culture.iso);
      if(foundIndex > -1){
        this.mapCulturesSelected.splice(foundIndex,1);
      } else {
        let foundOtherIndex = this.mapCultures.findIndex(mc=>mc.iso === culture.iso);
        if (foundOtherIndex >-1)
        this.mapCulturesSelected.push(this.mapCultures[foundOtherIndex])
      }
      this.mapCulturesSelectedIsos = this.mapCulturesSelected.map(mc=>mc.iso);;
      this.mapFeatures = this.updateMapFeatures(this.mapCultures,this.mapCulturesGeoJson,this.cultureSlugsFromSurveyTags, this.mapCulturesSelected);

    }
  }
  
  gotoSurvey(surveysAsContentItem: ContentItem){
    if (surveysAsContentItem){
      this.router.navigate(['quiz/'+surveysAsContentItem.slug], {relativeTo: this.route});
    }
  }
  handleHubBranding (hubMicro: HubMicro){
      this.hubMicro = hubMicro;
      if (this.hubMicro?.media?.length){
        this.featureImage = this.hubMicro.media.find(m => m.category==='profile'&& m.type==='feature');
        this.bannerImage = this.hubMicro.media.find(m => m.category==='profile'&& m.type==='general');
      } else {
        this.bannerImage = this.defaultBannerImage;
      }
  }

  navigateTo (slug){
    this.router.navigate(['/'+slug]);
  }
  ngOnInit(): void {
    this.subdomain = this.applicationService.getSubdomain();
    if(!this.subdomain){
      this.communityStatistic = {number:100, titleTranslationKey: 'common.interculturalists', titleText:null};
    }
    this.getSurveys('cultures','knowledge',false);
    this.hubMicroSubscription = this.brandDisplayService.currentHubMicro.subscribe(hubMicro =>{
      this.handleHubBranding(hubMicro);
    });
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
        this.getSurveys('cultures','knowledge',true);
      }
    })
  }

  ngOnDestroy () {
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
    if (this.culturesSubscription){
      this.culturesSubscription.unsubscribe();
    }
  }

}
