<!-- Turn on for homepage  -->
<!-- <header class="nav-reset nav-bg-dark" [class.nav-bg-dark]="headerScrollOffsetExceeded"> -->
<!-- <header class="nav-reset nav-bg-dark" [class.nav-bg-dark]="headerScrollOffsetExceeded && currentRouteIsHomepage"> -->
<!-- <header class="nav-reset"
  [class.nav-bg-dark]="!currentRouteIsHomepage || (currentRouteIsHomepage && headerScrollOffsetExceeded)">
  <main-navigation-type-two [user]="user" [logo]="'culturequestion'" [navLinks]="mainNavigationLinks"
    (doSideNavToggle)="toggleSidenav()" (logout)="logout()" [loading]="loading" [darkModeOn]="true">
  </main-navigation-type-two>
</header> -->
<!-- Turn on for homepage  -->
<!-- <main>  -->
<!-- <main [ngClass]="{'fixed-nav-offset':!currentRouteIsHomepage}">
  <section class="main-section">
    <multisite-breadcrumb [breadcrumbFixedWidth]="true"></multisite-breadcrumb>
    <router-outlet></router-outlet>
  </section>
</main>

<footer class="footer-bg-dark">
  <multisite-footer [logo]="'culturequestion'" [darkModeOn]="true"
    [summaryTranslationKey]="'culturequestion.about_summary'"></multisite-footer>
</footer> -->

<div class="wrap">
  <main class="main-section" (scroll)="onScroll($event)">
    <header class="nav-reset"
      [class.nav-bg-dark]="!currentRouteIsHomepage"
      [class.nav-bg-active]="currentRouteIsHomepage && headerScrollOffsetExceeded">
      <main-navigation-type-two [user]="user" [logo]="'culturequestion'" [navLinks]="mainNavigationLinks"
         (logout)="logout()" [loading]="loading" [darkModeOn]="true">
      </main-navigation-type-two>
    </header>
    <main [ngClass]="{'fixed-nav-offset':!currentRouteIsHomepage}">
        <multisite-breadcrumb [breadcrumbFixedWidth]="true"></multisite-breadcrumb>
        <router-outlet></router-outlet>
    </main>

    <footer class="footer-bg-dark">
      <multisite-footer [logo]="'culturequestion'" [darkModeOn]="true"
        [summaryTranslationKey]="'culturequestion.about_summary'"></multisite-footer>
    </footer>
  </main>
</div>